import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/apps/app/src/components/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TanstackQueryProvider"] */ "/vercel/path0/apps/app/src/providers/query-client-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Nunito\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-nunito\",\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\"}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Fira_Code\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-fira-code\",\"display\":\"swap\"}],\"variableName\":\"firaCode\"}");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/packages/ui/src/components/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/globals.css");
